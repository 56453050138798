import axios from 'axios';

export const getEgrzData = (query) => new Promise((resolve, reject) => {
    axios.get(`https://open-api.egrz.ru/api/PublicRegistrationBook?$filter=(contains(tolower(ExpertiseObjectNameAndAddress),tolower(%27${query}%27)))&$orderby=ExpertiseDate%20desc%20&$count=true&$top=5&$skip=0`)
        .then((response) => {
            switch (response.status) {
                case 200: {
                    resolve(response);
                    break;
                }
                default: {
                    reject(new Error('Во время выполнения запроса произошла ошибка'));
                    break;
                }
            }
        })
        .catch((error) => {
            if (!error.response) {
                reject(new Error('Ошибка сети. Проверьте ваше подключение к интернету.'));
            } else {
                reject(new Error(error.response.data.message || error.message));
            }
        });
});
