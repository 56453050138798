






















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import './scss/PopupRegistryData.scss';
import debounce from '@/modules/debounce';
import { getEgrzData } from '@utility/egrzData.js';

const projectDocumentsNamespace = namespace('storeProjectDocuments');
const NSProject = namespace('storeProject');

@Component({
    name: 'PopupRegistryData',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    }
})

export default class PopupRegistryData extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        textField: HTMLFormElement
    }

    objectTitle: string = '';
    objectAddress: string = '';
    objectDeveloper: string = '';
    objectTechnicalCustomer: string = '';
    searchQuery: string = '';
    emptyResults: boolean = false;
    debounceDelay: number = 300;
    searchResults: any[] = [];
    preloaderRegistryDataShow: boolean = false;

    get debouncedSearch() {
        return debounce(this.getData, this.debounceDelay);
    }

    checkUpdateData(item) {
        this.$emit('check-update-data', item);
        this.closePopup();
    }

    getData() {
        this.preloaderRegistryDataShow = true;
        getEgrzData(this.searchQuery).then((data) => {
            this.emptyResults = false;
            if (data.data.value && data.data.value.length) {
                this.searchResults = data.data.value;
            } else {
                this.searchResults = [];
                this.emptyResults = true;
            }
        }).finally(() => {
            this.preloaderRegistryDataShow = false;
        });
    }

    openPopup() {
        this.$refs.basePopup.openPopup();
        this.$refs.textField.focusInput();
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
    }

    onClose() {
        this.emptyResults = false;
        this.searchQuery = '';
        this.$refs.textField.setFieldValue('');
        this.searchResults = [];
    }

    setData(data) {
        this.objectTitle = data.title;
        this.objectAddress = data.address;
        this.objectDeveloper = data.developer;
        this.objectTechnicalCustomer = data.technicalCustomer;
    }
}
